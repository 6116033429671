/*

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.TabsPageModule)
  },
  { path: 'modal-alarm-edit-subscribers', loadChildren: './alarms/modal-alarm-edit-subscribers/modal-alarm-edit-subscribers.module#ModalAlarmEditSubscribersPageModule' },
  { path: 'modal-alarm-add-subscriber', loadChildren: './alarms/modal-alarm-add-subscriber/modal-alarm-add-subscriber.module#ModalAlarmAddSubscriberPageModule' },
  { path: 'modal-alarm-settings', loadChildren: './alarms/modal-alarm-settings/modal-alarm-settings.module#ModalAlarmSettingsPageModule' },
  { path: 'modal-alarm-history', loadChildren: './alarms/modal-alarm-history/modal-alarm-history.module#ModalAlarmHistoryPageModule' },
  { path: 'modal-alarm-settings-add-rule', loadChildren: './alarms/modal-alarm-settings-add-rule/modal-alarm-settings-add-rule.module#ModalAlarmSettingsAddRulePageModule' },
  { path: 'modal-report-page', loadChildren: './reports/modal-report-page/modal-report-page.module#ModalReportPagePageModule' },
  { path: 'modal-chart-settings', loadChildren: './dashboard/modal-chart-settings/modal-chart-settings.module#ModalChartSettingsPageModule' },
  { path: 'modal-present-report', loadChildren: './reports/modal-present-report/modal-present-report.module#ModalPresentReportPageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'debug', loadChildren: './debug/debug.module#DebugPageModule' },
  { path: 'modal-manual-reading', loadChildren: './reports/modal-manual-reading/modal-manual-reading.module#ModalManualReadingPageModule' },
  { path: 'modal-permissions-wizard', loadChildren: './modal-permissions-wizard/modal-permissions-wizard.module#ModalPermissionsWizardPageModule' },
  { path: 'modal-permissions-apple-wizard', loadChildren: './modal-permissions-apple-wizard/modal-permissions-apple-wizard.module#ModalPermissionsAppleWizardPageModule' },
  { path: 'devir', loadChildren: './devir/devir.module#DevirPageModule' },
  { path: 'modal-floorplan-settings', loadChildren: './dashboard/modal-floorplan-settings/modal-floorplan-settings.module#ModalFloorplanSettingsPageModule' },
  { path: 'modal-heatmap-settings', loadChildren: './dashboard/modal-heatmap-settings/modal-heatmap-settings.module#ModalHeatmapSettingsPageModule' },
  { path: 'stations', loadChildren: './stations/stations.module#StationsPageModule' },
  { path: 'modal-station-actions', loadChildren: './stations/modal-station-actions/modal-station-actions.module#ModalStationActionsPageModule' },
  { path: 'modal-tree', loadChildren: './modal-tree/modal-tree.module#ModalTreePageModule' },
  { path: 'custom-dialog', loadChildren: './utils/custom-dialog/custom-dialog.module#CustomDialogPageModule' },
  { path: 'map', loadChildren: './map/map.module#MapPageModule' },
  { path: 'dataexplorer', loadChildren: './dataexplorer/dataexplorer.module#DataexplorerPageModule' },
  { path: 'document', loadChildren: './document/document.module#DocumentPageModule' },
  { path: 'parkingmap', loadChildren: './parking-map/parking-map.module#ParkingMapPageModule' },
  { path: 'modal-alarm-state-comment', loadChildren: './alarms/modal-alarm-state-comment/modal-alarm-state-comment.module#ModalAlarmStateCommentPageModule' },
  { path: 'modal-add-dashboard-panel', loadChildren: './dashboard/modal-add-dashboard-panel/modal-add-dashboard-panel.module#ModalAddDashboardPanelPageModule' },
  { path: 'modal-alarm-add-alarm', loadChildren: './alarms/modal-alarm-add-alarm/modal-alarm-add-alarm.module#ModalAlarmAddAlarmPageModule' },
  { path: 'modal-station-present', loadChildren: './stations/modal-station-present/modal-station-present.module#ModalStationPresentPageModule' },
  { path: 'modal-users-edit', loadChildren: './users/modal-users-edit/modal-users-edit.module#ModalUsersEditPageModule' },
  { path: 'modal-edit-dashboard-panel', loadChildren: './dashboard/modal-edit-dashboard-panel/modal-edit-dashboard-panel.module#ModalEditDashboardPanelPageModule' },
  { path: 'modal-alarm-edit-alarm', loadChildren: './alarms/modal-alarm-edit-alarm/modal-alarm-edit-alarm.module#ModalAlarmEditAlarmPageModule' },
  { path: 'modal-station-formulas', loadChildren: './stations/modal-station-formulas/modal-station-formulas.module#ModalStationFormulasPageModule' },
  { path: 'users', loadChildren: './users/users.module#UsersPageModule' },
  { path: 'datamanipulation', loadChildren: './data-manipulation/data-manipulation.module#DataManipulationPageModule' },
  { path: 'modal-edit-data', loadChildren: './data-manipulation/modal-edit-data/modal-edit-data.module#ModalEditDataPageModule' },
  { path: 'modal-users-add', loadChildren: './users/modal-users-add/modal-users-add.module#ModalUsersAddPageModule' },
  { path: 'forgot', loadChildren: './login/password-forgot/password-forgot.module#PasswordForgotPageModule' },
  { path: 'passwd', loadChildren: './login/password-set/password-set.module#PasswordSetPageModule' },
  { path: 'modal-mark-sensor-floorplan', loadChildren: './dashboard/modal-add-dashboard-panel/modal-mark-sensor-floorplan/modal-mark-sensor-floorplan.module#ModalMarkSensorFloorplanPageModule' },
  { path: 'modal-alarm-edit-all-alarms', loadChildren: './alarms/modal-alarm-edit-all-alarms/modal-alarm-edit-all-alarms.module#ModalAlarmEditAllAlarmsPageModule' }
  // { path: 'modal-alarm-add-subscriber', loadChildren: './alarms/modal-alarm-add-subscriber/modal-alarm-add-subscriber.module#ModalAlarmAddSubscriberPageModule' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}


*/



import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RedirectComponent } from './login/redirect/redirect.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.TabsPageModule)
  },
  { path: 'modal-alarm-edit-subscribers', loadChildren:   () => import('./alarms/modal-alarm-edit-subscribers/modal-alarm-edit-subscribers.module').then(x => x.ModalAlarmEditSubscribersPageModule)},
  { path: 'modal-alarm-add-subscriber', loadChildren: () => import('./alarms/modal-alarm-add-subscriber/modal-alarm-add-subscriber.module').then(x => x.ModalAlarmAddSubscriberPageModule)},
  { path: 'modal-alarm-settings', loadChildren: () => import('./alarms/modal-alarm-settings/modal-alarm-settings.module').then(x => x.ModalAlarmSettingsPageModule)},
  { path: 'modal-alarm-history', loadChildren: () => import('./alarms/modal-alarm-history/modal-alarm-history.module').then(x => x.ModalAlarmHistoryPageModule)},
  { path: 'modal-alarm-settings-add-rule', loadChildren: () => import('./alarms/modal-alarm-settings-add-rule/modal-alarm-settings-add-rule.module').then(x => x.ModalAlarmSettingsAddRulePageModule)},
  { path: 'modal-report-page', loadChildren:  () => import('./reports/modal-report-page/modal-report-page.module').then(x => x.ModalReportPagePageModule)},
  { path: 'modal-chart-settings', loadChildren: () => import('./dashboard/modal-chart-settings/modal-chart-settings.module').then(x => x.ModalChartSettingsPageModule)},
  { path: 'modal-present-report', loadChildren: () => import('./reports/modal-present-report/modal-present-report.module').then(x => x.ModalPresentReportPageModule)},
  { path: 'login', loadChildren: () => import('./login/login.module').then(x => x.LoginPageModule)},
  { path: 'debug', loadChildren: () => import('./debug/debug.module').then(x => x.DebugPageModule)},
  { path: 'modal-manual-reading', loadChildren: () => import('./reports/modal-manual-reading/modal-manual-reading.module').then(x => x.ModalManualReadingPageModule)},
  { path: 'modal-permissions-wizard', loadChildren: () => import('./modal-permissions-wizard/modal-permissions-wizard.module').then(x => x.ModalPermissionsWizardPageModule)},
  { path: 'modal-permissions-apple-wizard', loadChildren: () => import('./modal-permissions-apple-wizard/modal-permissions-apple-wizard.module').then(x => x.ModalPermissionsAppleWizardPageModule)},
  { path: 'devir', loadChildren: () => import('./devir/devir.module').then(x => x.DevirPageModule)},
  { path: 'modal-floorplan-settings', loadChildren: () => import('./dashboard/modal-floorplan-settings/modal-floorplan-settings.module').then(x => x.ModalFloorplanSettingsPageModule)},
  { path: 'modal-heatmap-settings', loadChildren: () => import('./dashboard/modal-heatmap-settings/modal-heatmap-settings.module').then(x => x.ModalHeatmapSettingsPageModule)},
  { path: 'stations', loadChildren: () => import('./stations/stations.module').then(x => x.StationsPageModule)},
  { path: 'modal-station-actions', loadChildren: () => import('./stations/modal-station-actions/modal-station-actions.module').then(x => x.ModalStationActionsPageModule)},
  { path: 'map', loadChildren: () => import('./map/map.module').then(x => x.MapPageModule)},
  { path: 'dataexplorer', loadChildren: () => import('./dataexplorer/dataexplorer.module').then(x => x.DataexplorerPageModule)},
  { path: 'document', loadChildren: () => import('./document/document.module').then(x => x.DocumentPageModule)},
  { path: 'parkingmap', loadChildren: () => import('./parking-map/parking-map.module').then(x => x.ParkingMapPageModule)},
  { path: 'modal-alarm-state-comment', loadChildren: () => import('./alarms/modal-alarm-state-comment/modal-alarm-state-comment.module').then(x => x.ModalAlarmStateCommentPageModule)},
  { path: 'modal-add-dashboard-panel', loadChildren: () => import('./dashboard/modal-add-dashboard-panel/modal-add-dashboard-panel.module').then(x => x.ModalAddDashboardPanelPageModule)},
  { path: 'modal-alarm-add-alarm', loadChildren: () => import('./alarms/modal-alarm-add-alarm/modal-alarm-add-alarm.module').then(x => x.ModalAlarmAddAlarmPageModule)},
  { path: 'modal-station-present', loadChildren: () => import('./stations/modal-station-present/modal-station-present.module').then(x => x.ModalStationPresentPageModule)},
  { path: 'modal-edit-dashboard-panel', loadChildren: () => import('./dashboard/modal-edit-dashboard-panel/modal-edit-dashboard-panel.module').then(x => x.ModalEditDashboardPanelPageModule)},
  { path: 'modal-alarm-edit-alarm', loadChildren: () => import('./alarms/modal-alarm-edit-alarm/modal-alarm-edit-alarm.module').then(x => x.ModalAlarmEditAlarmPageModule)},
  { path: 'modal-station-formulas', loadChildren: () => import('./stations/modal-station-formulas/modal-station-formulas.module').then(x => x.ModalStationFormulasPageModule)},
  { path: 'users', loadChildren: () => import('./users/users.module').then(x => x.UsersPageModule)},
  { path: 'datamanipulation', loadChildren: () => import('./data-manipulation/data-manipulation.module').then(x => x.DataManipulationPageModule)},
  { path: 'modal-edit-data', loadChildren: () => import('./data-manipulation/modal-edit-data/modal-edit-data.module').then(x => x.ModalEditDataPageModule)},
  { path: 'modal-users-add', loadChildren: () => import('./users/modal-users-add/modal-users-add.module').then(x => x.ModalUsersAddPageModule)},
  { path: 'modal-users-permissions', loadChildren: () => import('./users/modal-users-permissions/modal-users-permissions.module').then(x => x.ModalUsersPermissionsPageModule)},
  { path: 'forgot', loadChildren: () => import('./login/password-forgot/password-forgot.module').then(x => x.PasswordForgotPageModule)},
  { path: 'passwd', loadChildren: () => import('./login/password-set/password-set.module').then(x => x.PasswordSetPageModule)},
  { path: 'modal-mark-sensor-floorplan', loadChildren: () => import('./dashboard/modal-add-dashboard-panel/modal-mark-sensor-floorplan/modal-mark-sensor-floorplan.module').then(x => x.ModalMarkSensorFloorplanPageModule)},
  { path: 'livedata', loadChildren: () => import('./livedata/livedata.module').then( x => x.LivedataPageModule) },
  { path: 'modal-live-data-list-applications', loadChildren: () => import('./livedata/modal-live-data-list-applications/modal-live-data-list-applications.module').then( x => x.ModalLiveDataListApplicationsPageModule)},
 // { path: 'subscriptions', loadChildren: './subscriptions/subscriptions.module#SubscriptionsPageModule' },
  { path: 'modal-add-subscription', loadChildren: () => import('./subscriptions/modal-add-subscription/modal-add-subscription.module').then(x => x.ModalAddSubscriptionPageModule)},
  { path: 'modal-edit-subscription', loadChildren: () => import('./subscriptions/modal-edit-subscription/modal-edit-subscription.module').then(x => x.ModalEditSubscriptionPageModule)},
  {
    path: 'modal-map-marker-content',
    loadChildren: () => import('./dashboard/modal-map-marker-content/modal-map-marker-content/modal-map-marker-content.module').then( m => m.ModalMapMarkerContentPageModule)
  },
  {
    path: 'modal-users-permissions',
    loadChildren: () => import('./users/modal-users-permissions/modal-users-permissions.module').then( m => m.ModalUsersPermissionsPageModule)
  },
  {
    path: 'modal-edit-table-dashboard-panel',
    loadChildren: () => import('./dashboard/modal-edit-table-dashboard-panel/modal-edit-table-dashboard-panel.module').then( m => m.ModalEditTableDashboardPanelPageModule)
  },
  {
    path: 'modal-edit-floorplan-dashboard-panel',
    loadChildren: () => import('./dashboard/modal-edit-floorplan-dashboard-panel/modal-edit-floorplan-dashboard-panel.module').then( m => m.ModalEditFloorplanDashboardPanelPageModule)
  },
  {
    path: 'modal-edit-historical-table-panel',
    loadChildren: () => import('./dashboard/modal-edit-historical-table-panel/modal-edit-historical-table-panel.module').then( m => m.ModalEditHistoricalTablePanelPageModule)
  },
  {
    path: 'sso-login',
    loadChildren: () => import('./login/sso/sso-login/sso-login.module').then( m => m.SsoLoginPageModule)
  },
  {
    path: 'redirect/:token',
    component: RedirectComponent
  }
  
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
