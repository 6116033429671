import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeNodeComponent } from './tree-node/tree-node.component';
import { IonicModule } from '@ionic/angular';
@NgModule({
	declarations: [TreeNodeComponent],
	imports: [IonicModule, CommonModule],
	exports: [TreeNodeComponent]
})
export class ComponentsModule {}
