// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isOnPrem: false
};

export const API_URL = 'https://6hpfwl4s1i.execute-api.eu-west-1.amazonaws.com/dev';
//export const API_SUBSCRIPTIONS = 'wss://brmdgaad29.execute-api.eu-west-1.amazonaws.com/dev';
export const API_SUBSCRIPTIONS = 'wss://20ao3niawb.execute-api.ap-southeast-2.amazonaws.com/prod';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
